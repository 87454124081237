<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Monitoring SKP</h3>
          </template>
          <v-toolbar flat>
            <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details outlined dense></v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" hide-details outlined dense></v-text-field>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-progress-linear
              indeterminate
              color="pink"
              v-if="loading"
          ></v-progress-linear>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,20,30]}"
            class="pegawai-table"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nip_nama="{ item }">
              <!-- <div v-html="item.nip"></div> -->
              <b>{{ item.peg_nama }}</b><br>{{ item.peg_nip }}
            </template>
            <template v-slot:item.skp_tahunan="{ item }">
              <br>
              <div v-if="item.skp_tahunan">
                <b><label>Sudah Input SKP</label></b>
                <br><br>
                <v-btn x-small @click="lihatDataSKPTahunan(item)" class="primary" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data</v-btn>
              </div>
              <div v-else>
                <label>Belum Input SKP</label><br>
              </div>
              <br>
            </template>
            <template v-slot:item.skp1="{ item }">
              <!-- <div v-html="item.skp1"></div>
              <v-btn class="secondary" x-small dark><v-icon x-small>mdi-magnify</v-icon> Lihat Riwayat Jabatan</v-btn> -->
              <div v-if="item.skp_triwulan_1">
                <div v-if="item.lapor_skp_triwulan_1">
                    <b><label>Sudah Lapor SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,1)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-if="item.validasi_skp_triwulan_1">
                    <b><label>Sudah Divalidasi</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,1)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-else>
                    <b><label>Sudah Input SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,1)" class="green darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Input SKP</v-btn>
                </div>
              </div>
              <div v-else>
                <br>
                <label>Belum Input SKP</label><br>
              </div>
            </template>
            <template v-slot:item.skp2="{ item }">
              <!-- <div v-html="item.skp2"></div>
              <v-btn class="secondary" x-small dark><v-icon x-small>mdi-magnify</v-icon> Lihat Riwayat Jabatan</v-btn> -->
              <div v-if="item.skp_triwulan_2">
                <div v-if="item.lapor_skp_triwulan_2">
                    <b><label>Sudah Lapor SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,2)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-if="item.validasi_skp_triwulan_2">
                    <b><label>Sudah Divalidasi</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,2)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-else>
                    <b><label>Sudah Input SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,2)" class="green darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Input SKP</v-btn>
                </div>
              </div>
              <div v-else>
                <br>
                <label>Belum Input SKP</label><br>
              </div>
            </template>
            <template v-slot:item.skp3="{ item }">
              <!-- <div v-html="item.skp3"></div>
              <v-btn class="secondary" x-small dark><v-icon x-small>mdi-magnify</v-icon> Lihat Riwayat Jabatan</v-btn> -->
              <div v-if="item.skp_triwulan_3">
                <div v-if="item.lapor_skp_triwulan_3">
                    <b><label>Sudah Lapor SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,3)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-if="item.validasi_skp_triwulan_3">
                    <b><label>Sudah Divalidasi</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,3)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-else>
                    <b><label>Sudah Input SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,3)" class="green darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Input SKP</v-btn>
                </div>
              </div>
              <div v-else>
                <label>Belum Input SKP</label><br>
              </div>
            </template>
            <template v-slot:item.skp4="{ item }">
              <!-- <div v-html="item.skp4"></div>
              <v-btn class="secondary" x-small dark><v-icon x-small>mdi-magnify</v-icon> Lihat Riwayat Jabatan</v-btn> -->
              <div v-if="item.skp_triwulan_4">
                <div v-if="item.lapor_skp_triwulan_4">
                    <b><label>Sudah Lapor SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,4)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-if="item.validasi_skp_triwulan_4">
                    <b><label>Sudah Divalidasi</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,4)" class="red darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Data SKP</v-btn><br>
                </div>
                <div v-else>
                    <b><label>Sudah Input SKP</label></b>
                    <br><br>
                    <v-btn x-small @click="lihatDataSKPTriwulan(item,4)" class="green darken-2 white--text" ><v-icon small>mdi-file</v-icon>&nbsp;Lihat Input SKP</v-btn>
                </div>
              </div>
              <div v-else>
                <label>Belum Input SKP</label><br>
              </div>
            </template>
            <template v-slot:item.jabatan="{item}">
              <v-btn class="accent" x-small @click="lihatRiwayatJabatan(item)" dark><v-icon x-small>mdi-magnify</v-icon> Lihat Riwayat Jabatan</v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogModalListSKP" persistent>
      <ModalListSKP :skp="dataSKP" :pegawai="pegawai" @onClose="(x)=>{dialogModalListSKP=x}" @onTableChange="(x)=>updateTable(x)"/>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import MonitoringSKPService from '@/services/MonitoringSKPService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import ModalListSKP from './ModalListSKP.vue'
export default {
  components:{
    ModalListSKP
  },
  data(){
    return{
      toggle_triwulan:'',
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      skpd:[],

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'NIP-Nama', value:'nip_nama', sortable:false, class:'header-index-profile'},
        {text:'SKP Tahunan', value:'skp_tahunan', sortable:false, class:'header-index-profile'},
        {text:'SKP Triwulan 1', value:'skp1', sortable:false, class:'header-index-profile'},
        {text:'SKP Triwulan 2', value:'skp2', sortable:false, class:'header-index-profile'},
        {text:'SKP Triwulan 3', value:'skp3', sortable:false, class:'header-index-profile'},
        {text:'SKP Triwulan 4', value:'skp4', sortable:false, class:'header-index-profile'},
        {text:'Riwayat Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      dialogReset:false,
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectSKPD:'',
      search: '',
      dialogModalListSKP:false,
      dataSKP:[],
      pegawai:{}
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted() {
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, satuan_kerja_id:this.selectSKPD, search:val[1], page:this.tableValue.page})
    })
  },

  created(){
    let val = {itemsPerPage:10, page: 1, satuan_kerja_id:this.selectSKPD}
    this.updateTable(val)
    this.getListSKPD()
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    }
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      var params = {}
      params.kolok = this.tableValue.kode_skpd ? this.tableValue.kode_skpd : null
      params.bulan = this.tableValue.bulan ? this.tableValue.bulan : null
      MonitoringSKPService.getData({
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page,
        params:params,
        role:'superadmin',
        satuan_kerja_id:this.tableValue.satuan_kerja_id })
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.count
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    getListSKPD(){
      var url = "v1/get-data-skpd-satuan-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },
    lihatDataSKPTahunan(e){
      this.pegawai = e
      this.pegawai.periode = 'tahunan'
      MonitoringSKPService.getDataSKP({
        nip:e.peg_nip,
        periode:'tahunan'})
      .then((response)=>{
        this.dataSKP = response.data.data
        this.dataSKP.totalKeseluruhan = response.data.data.length
        this.dataSKP.riwayat = false
        this.dataSKP.title = ' Tahunan'
        console.log(this.items)
        let res = response.data
        if (res.data.length > 0){
          this.dialogModalListSKP = true
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    lihatDataSKPTriwulan(e,p){
      this.pegawai = e
      this.pegawai.periode = p
      MonitoringSKPService.getDataSKP({
        nip:e.peg_nip,
        periode:p})
      .then((response)=>{
        this.dataSKP = response.data.data
        this.dataSKP.totalKeseluruhan = response.data.data.length
        this.dataSKP.riwayat = false
        this.dataSKP.title = ' Triwulan '+p
        console.log(this.items)
        let res = response.data
        if (res.data.length > 0){
          this.dialogModalListSKP = true
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    lihatRiwayatJabatan(e){
      MonitoringSKPService.getDataRiwayatJabatan({
        "nip":e.peg_nip})
      .then((response)=>{
        this.dataSKP = response.data.data
        this.dataSKP.totalKeseluruhan = response.data.data.length
        this.dataSKP.riwayat = true
        console.log(this.items)
        let res = response.data
        if (res.data.length > 0){
          this.dialogModalListSKP = true
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
